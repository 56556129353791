








import {Component, Vue} from 'vue-property-decorator';
import {IPaginationOptions, sortList, sortType} from '@/interfaces';
import {getPaginationOptionsFromQuery, getSortFromQuery, paginationOptions} from '@/utilities/table';
import {getSortOptions} from '@/dictionaries/sort.dictionary';

@Component
export default class ClaimTable extends Vue {
  private paginationOptions: IPaginationOptions = paginationOptions();

  private sortOptions: object = {};
  private sortBy: sortType[] = [sortList.createdAtDesc, sortList.createdAtAsc, sortList.amountDesc, sortList.amountAsc];

  private headers = [
    { text: this.$tc('table.claims.refNumber'), value: 'refNumber', active: true },
    { text: this.$tc('table.status'), value: 'status', active: true },
    { text: this.$tc('table.claims.address'), value: 'address', active: true },
    { text: this.$tc('table.claims.policyId'), value: 'policyId', active: true },
    { text: this.$tc('table.claims.landlord'), value: 'beneficiaryName', active: true },
    { text: this.$tc('table.claims.date_of_loss'), value: 'date', active: true },
    { text: this.$tc('table.claims.amount'), value: 'amount', align: 'right', active: true},
    { text: '', value: 'link', class: 'link', cellClass: 'link'},
  ];

  private get attrs() {
    return {
      headers: this.headers,
      items: this.getClaims,
      'server-items-length': this.getClaimsCount,
      loading: this.visible,
      options: this.paginationOptions,
      sortBy: this.sortBy,
    };
  }

  private get actions() {
    return {
      clickLink: (id: string) => this.$router.push({name: 'claim-details', params: {id}}),
      pagination: (event) => {
        this.paginationOptions = event;
        this.dispatchClaims(event);
      },
      sortOptions: (event) => this.sortOptions = event,
      setSortOptions: () => this.dispatchClaims(this.paginationOptions),
    };
  }

  private dispatchClaims(paginationOptions = this.paginationOptions) {
    this.$store.dispatch('getClaims', {
      page: paginationOptions.page,
      pageSize: paginationOptions.itemsPerPage,
      ...this.sortOptions
    });
  }

  private get getClaims() {
    return this.$store.getters.getClaims;
  }

  private get getClaimsCount() {
    return this.$store.getters.getClaimsCount;
  }

  public get visible() {
    return this.$store.getters.isUniformLoading('getClaims');
  }

  private created() {
    this.paginationOptions = getPaginationOptionsFromQuery(this.paginationOptions);
    this.sortOptions = getSortFromQuery(getSortOptions(sortList.createdAtDesc));
  }
}
