







import {Component, Vue} from 'vue-property-decorator';
import ClaimTable from '@/pages/Claim/Claim.table.vue';
import {i18n} from '@/main';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.claim.title'),
      meta: [{
        name: 'description',
        content: i18n.tc('meta.claim.description')
      }]
    };
  },
  components: {ClaimTable}
})
export default class ClaimPage extends Vue {}
